<script>
import Layout from "@/router/layouts/main";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: [
        {
          type: "line",
          title: "Ativos",
          value: "0",
          icon: "bx-purchase-tag-alt",
        },
        {
          type: "line",
          title: "Inativos",
          value: "0",
          icon: "bx-purchase-tag-alt",
        },
        {
          type: "line",
          title: "Rascunho",
          value: "0",
          icon: "bx-purchase-tag-alt",
        },
      ],
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Produtos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum produto encontrato
        </div>
      </div>
    </div>
  </Layout>
</template>